<template>
  <b-card no-body class="int-home-card" :class="data.color">
    <b-overlay :show="show" variant="primary" spinner-type="grow" spinner-variant="primary" :opacity="0" blur="4px" rounded="sm">
      <i :class="`${data.icon} int-home-card-trend`" />
      <div class="int-home-card-head" v-if="Object.keys(data).length">
        <h4 class="int-home-card-meta">
          <span>{{ data.title }}</span>
          <h4 v-if="data.type == 'number'">{{ data.value }}</h4>
          <h4 v-if="data.type == 'price'">{{ data.value.toplam | cur }} {{ data.value._id }}</h4>
          <h5 v-if="data.type == 'price'">{{ data.value.count }} Adet</h5>
        </h4>
        <i :class="`${data.icon} int-home-card-head-icon`" />
      </div>
      <!-- <div v-if="data.chart" class="int-home-card-chart">
  
        <LineChartGenerate :styles="{ color: 'white' }" />
      </div> -->
      <div class="int-home-card-foot">
        <div class="int-home-card-compare">{{ new Date() | moment }} Analiz</div>
      </div>
    </b-overlay>
  </b-card>
</template>

<script>
import { defineComponent } from '@vue/composition-api';
import LineChartGenerate from '../chart/LineChartGenerate.vue';

export default defineComponent({
  components: { LineChartGenerate },
  props: {
    show: [Boolean],
    data: {
      type: Object,
      required: true,
    },
  },
  setup() {
    const expo = {};
    return { ...expo };
  },
});
</script>

<style lang="scss" scoped>
.int-home-card {
  position: relative;
  border-radius: 8px;
  padding: 22px;
  border: 0px;
  width: 100%;
  height: 100%;
  transition: all 0.2s ease-in-out;
  &:hover {
    -webkit-box-shadow: 0px 0px 10px 0px rgba(173, 173, 173, 0.71);
    -moz-box-shadow: 0px 0px 10px 0px rgba(173, 173, 173, 0.71);
    box-shadow: 0px 0px 10px 0px rgba(173, 173, 173, 0.71);
    transition: all 0.2s ease-in-out;
  }
  &.green {
    background: linear-gradient(to left, #4eda89, #1a9f53);
    > .b-overlay-wrap .int-home-card-trend {
      color: #1a9f53;
    }
    > .b-overlay-wrap .int-home-card-head .int-home-card-head-icon {
      background: linear-gradient(#27bf68, #1a9f53);
    }
  }
  &.purple {
    background: linear-gradient(to left, #ed68ff, #be0ee1);
    > .b-overlay-wrap .int-home-card-trend {
      color: #be0ee1;
    }
    > .b-overlay-wrap .int-home-card-head .int-home-card-head-icon {
      background: linear-gradient(#de2fff, #be0ee1);
    }
  }
  &.blue {
    background: linear-gradient(to left, #64b3f6, #2b77e5);
    > .b-overlay-wrap .int-home-card-trend {
      color: #2b77e5;
    }
    > .b-overlay-wrap .int-home-card-head .int-home-card-head-icon {
      background: linear-gradient(#4094f1, #2b77e5);
    }
  }
  &.orange {
    background: linear-gradient(to left, #f4d02b, #e1940e);
    > .b-overlay-wrap .int-home-card-trend {
      color: #e1940e;
    }
    > .b-overlay-wrap .int-home-card-head .int-home-card-head-icon {
      background: linear-gradient(#edb213, #e1940e);
    }
  }
  &.red {
    background: linear-gradient(to left, #fc4c4c, #e10e0e);
    > .b-overlay-wrap .int-home-card-trend {
      color: #e10e0e;
    }
    > .b-overlay-wrap .int-home-card-head .int-home-card-head-icon {
      background: linear-gradient(#ed1313, #e10e0e);
    }
  }
  .b-overlay-wrap {
    .int-home-card-trend {
      position: absolute;
      top: 0px;
      left: 0px;
      z-index: 1;
      font-size: 140px;
      opacity: 1;
    }
    .int-home-card-head {
      display: flex;
      align-items: start;
      justify-content: space-between;
      gap: 0px;
      margin-bottom: 40px;
      .int-home-card-meta {
        color: #fff;
        font-weight: 600;
        position: relative;
        z-index: 1;
        span {
          margin-bottom: 7px;
          font-size: 16px;
          font-weight: 500;
          text-transform: capitalize;
          display: block;
        }
      }
      .int-home-card-head-icon {
        width: 50px;
        height: 50px;
        line-height: 50px;
        border-radius: 8px;
        text-align: center;
        font-size: 28px;
        color: rgba(255, 255, 255, 0.67);
      }
    }
    .int-home-card-chart {
      z-index: 10;
      padding: 10px 0px;
    }
    .int-home-card-foot {
      position: relative;
      z-index: 1;
      display: flex;
      align-items: center;
      justify-content: space-between;
      gap: 0px;
      .int-home-card-compare {
        display: flex;
        align-items: center;
        justify-content: start;
        gap: 0px 5px;
        color: #fff;
      }
    }
  }
}
</style>
