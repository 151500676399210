<template>
  <div>
    <div class="text-center h5">Interyum E Ticaret v 2.0</div>

    <b-row>
      <b-col cols="12" md="4">
        <HomeCard :show="show" :data="homeCardsValue[0]" />
      </b-col>
      <b-col cols="12" md="4">
        <HomeCard :show="show" :data="homeCardsValue[1]" />
      </b-col>
      <b-col cols="12" md="4">
        <HomeCard :show="show" :data="homeCardsValue[2]" />
      </b-col>
      <b-col cols="12" md="4">
        <HomeCard :show="show" :data="homeCardsValue[3]" />
      </b-col>
      <b-col cols="12" md="4">
        <HomeCard :show="show" :data="homeCardsValue[4]" />
      </b-col>
      <b-col cols="12" md="4">
        <FirmaCard :show="show" />
      </b-col>
    </b-row>
    <b-row>
      <b-col cols="12" md="6">
        <b-card no-body>
          <b-overlay
            :show="show"
            variant="primary"
            spinner-type="grow"
            spinner-variant="primary"
            :opacity="0"
            blur="4px"
            rounded="sm"
          >
            <b-card-header class="bg-transparent">
              <h5>Tarayıcı ve Cihaz Analizi</h5>
            </b-card-header>
            <b-card-text>
              <b-alert v-if="!Object.keys(tarayicilar).length || !cihazlar.length" show variant="warning" class="text-center m-2">
                <h5>Bilgi</h5>
                <hr />
                <p>Gösterilecek veri yok</p>
              </b-alert>
              <b-row v-else>
                <b-col cols="12" md="6">
                  <!-- tarayıcı -->
                  <DoughnutChartGenerate :data="tarayicilar" />
                </b-col>
                <b-col cols="12" md="6">
                  <!-- cihaz -->
                  <DoughnutChartGenerate :data="cihazlar" />
                </b-col>
              </b-row>
            </b-card-text>
          </b-overlay>
        </b-card>
      </b-col>
      <b-col cols="12" md="6">
        <b-card no-body>
          <b-card-header class="bg-transparent">
            <h5>En Son Gelen Siparişler</h5>
          </b-card-header>
          <b-card-text>
            <SonGelenSiparisler />
          </b-card-text>
        </b-card>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import HomeCard from '@/components/cards/HomeCard.vue';
import FirmaCard from '@/components/cards/FirmaCard.vue';
import SonGelenSiparisler from './SonGelenSiparisler.vue';
import DoughnutChartGenerate from '@/components/chart/DoughnutChartGenerate.vue';
import store from '@/store';
import { ref, defineComponent, onMounted } from '@vue/composition-api';
import vuePositionSticky from 'vue-position-sticky';
export default defineComponent({
  components: { vuePositionSticky, HomeCard, FirmaCard, DoughnutChartGenerate, SonGelenSiparisler },
  setup() {
    const expo = {};
    expo.show = ref(false);
    expo.homeCardsValue = ref([
      {
        title: 'Toplam Üyeler',
        value: 0,
        icon: 'fad fa-user',
        color: 'green',
        type: 'number',
      },
      {
        title: 'Toplam Ziyaret',
        value: 0,
        icon: 'fad fa-people-carry',
        color: 'orange',
        type: 'number',
      },
      {
        title: 'Toplam Ürünler',
        value: 0,
        icon: 'fad fa-box-full',
        color: 'red',
        type: 'number',
      },
      {
        title: 'Toplam Sipariş',
        value: {
          _id: 'TRY',
          toplam: 0,
          count: 0,
        },
        icon: 'fad fa-file-chart-line',
        color: 'purple',
        type: 'price',
      },
      {
        title: 'Toplam Satış',
        value: {
          _id: 'TRY',
          toplam: 0,
          count: 0,
        },
        icon: 'fad fa-money-bill-wave',
        color: 'blue',
        chart: true,
        type: 'price',
      },
    ]);

    expo.tarayicilar = ref({});
    expo.cihazlar = ref({});
    expo.firmaBilgisi = ref({});

    onMounted(async () => {
      expo.show.value = true;
      await store.dispatch('dashboardAnaliz').then((res) => {
        if (res.data.success) {
          const data = res.data.result;
          expo.homeCardsValue.value[0].value = data.uyeler;
          expo.homeCardsValue.value[1].value = data.analiz;
          expo.homeCardsValue.value[2].value = data.urunler;
          expo.homeCardsValue.value[3].value = data.siparisler;

          if (data.siparisler.length) {
            expo.homeCardsValue.value[3].value._id = data.siparisler[0]._id;
            expo.homeCardsValue.value[3].value.toplam = data.siparisler[0].toplam;
            expo.homeCardsValue.value[3].value.count = data.siparisler[0].count;
          }
          if (data.satislar.length) {
            expo.homeCardsValue.value[4].value._id = data.satislar[0]._id;
            expo.homeCardsValue.value[4].value.toplam = data.satislar[0].toplam;
            expo.homeCardsValue.value[4].value.count = data.satislar[0].count;
          }
          expo.tarayicilar.value = data.tarayici;
          expo.cihazlar.value = data.cihaz;
          expo.show.value = false;
        }
      });
    });
    return { ...expo };
  },
});
</script>

<style lang="scss" scoped></style>
